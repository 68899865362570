import { IconButton } from '@chakra-ui/react';
import { PaginationButtonProps } from '../../types/collections';

function PaginationButton({ ariaLabel, icon, id, onClick, isDisabled }: PaginationButtonProps) {
  return (
    <IconButton
      aria-label={ariaLabel}
      variant="outline"
      onClick={onClick}
      isDisabled={isDisabled}
      border="1px"
      borderColor="neutral.200"
      ml="2"
      data-cy={id}
      icon={icon}
      _hover={
        isDisabled
          ? {}
          : {
              bg: 'primary.50',
              svg: {
                fill: 'primary.400',
              },
              borderColor: 'primary.400',
            }
      }
    />
  );
}

export default PaginationButton;

PaginationButton.defaultProps = {
  isDisabled: false,
};
