import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  Flex,
} from '@chakra-ui/react';
import { AppModalProps } from '../types/appModal';

/**
 * AppModal provides both Custom and Default behavior
 * Custom can have own header and footer
 * Default has its own header and footer which are controlled by props
 */
function AppModal({
  isOpen,
  onClose,
  size,
  modalTitle,
  children,
  closeText,
  onCloseComplete,
  primaryText,
  primaryColorScheme,
  formSubmitId,
  onClickPrimary,
  isPrimaryDisabled,
  otherActions,
  modalFooter,
  modalHeader,
  hideHeader,
  closeIconColor,
  hideCloseIcon,
  extras,
  modalContentWidth,
}: AppModalProps): JSX.Element {
  const defaultFooter = () => (
    <Flex justifyContent="space-between">
      {closeText && (
        <Button variant="neutral" onClick={onClose}>
          {closeText}
        </Button>
      )}
      <HStack>
        {otherActions}
        {!!primaryText && (
          <Button
            type={formSubmitId ? 'submit' : 'button'}
            colorScheme={primaryColorScheme}
            disabled={isPrimaryDisabled}
            onClick={onClickPrimary}
            form={formSubmitId}
            data-cy={primaryText}
          >
            {primaryText}
          </Button>
        )}
      </HStack>
    </Flex>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      size={size}
      onCloseComplete={onCloseComplete}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        alignItems={size === 'full' ? 'center' : 'unset'}
        data-cy="appModal"
        borderRadius={extras?.modalContentBorderRadius}
      >
        {!hideHeader && (
          <ModalHeader color="modal_header_color">{modalHeader || modalTitle}</ModalHeader>
        )}
        {!hideCloseIcon && (
          <ModalCloseButton
            _hover={{ bg: 'none' }}
            _active={{ bg: 'none' }}
            color={closeIconColor}
          />
        )}
        <ModalBody
          pl={extras?.modalBodyPadding?.left}
          pt={extras?.modalBodyPadding?.top}
          pr={extras?.modalBodyPadding?.right}
          pb={extras?.modalBodyPadding?.bottom}
          w={modalContentWidth}
        >
          {children}
        </ModalBody>
        <ModalFooter display="block" pt="0" w={modalContentWidth}>
          {modalFooter || defaultFooter()}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AppModal;

AppModal.defaultProps = {
  closeText: 'Close',
  primaryText: '',
  primaryColorScheme: null,
  formSubmitId: '',
  onClickPrimary: () => null,
  otherActions: null,
  modalHeader: null,
  modalFooter: null,
  isPrimaryDisabled: false,
  extras: {
    modalBodyPadding: {
      left: '1.5rem',
      top: '0.5rem',
      right: '1.5rem',
      bottom: '2rem',
    },
  },
  hideHeader: false,
  closeIconColor: 'inherit',
};
