import { Box, Button, Icon } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Row } from 'react-table';
import PlusCircle from '../../../assets/icons/PlusCircle';
import { DynamoScanItemKeys } from '../../../constants/collections';
import CollectionFooter from '../../../elements/collection/CollectionFooter';
import DataTable from '../../../elements/table/DataTable';
import { DynamoContextProps } from '../../../types/collections';

function DynamoData() {
  const {
    toggleDynamoItemModal,
    handleEdit,
    setShowHeaderItem,
    handlePaginationRecords,
    totalDocumentsCount,
    hasCollectionPermission,
    columns,
    itemKeys,
    filteredData,
    canGoToNextPage,
    handleLimits,
  } = useOutletContext<DynamoContextProps>();
  useEffect(() => {
    setShowHeaderItem(true);
  }, [setShowHeaderItem]);

  const lastColumn = {
    accessor: 'action',
    Header: (
      <Button
        variant="unstyled"
        onClick={toggleDynamoItemModal}
        height="4"
        textAlign="left"
        minWidth="auto"
        disabled={!hasCollectionPermission}
      >
        <Icon as={PlusCircle} fill="positive" fontSize="lg" />
      </Button>
    ),
  };

  const handleRowClick = (row: Row<object>) => {
    let data = {};
    const { original } = row;

    Object.keys(original).forEach((item: string) => {
      const value = original[item as keyof typeof original];
      if (value !== 'undefined') {
        if (itemKeys[item] === DynamoScanItemKeys.STRING) {
          data = { ...data, [item]: value };
        } else if (itemKeys[item] === DynamoScanItemKeys.NUMBER) {
          data = { ...data, [item]: Number(value) };
        } else if (itemKeys[item] === DynamoScanItemKeys.BOOLEAN) {
          data = { ...data, [item]: Boolean(value) };
        } else {
          data = { ...data, [item]: JSON.parse(value) };
        }
      }
    });

    handleEdit(data);
  };

  return (
    <Box>
      <DataTable
        data={filteredData}
        columns={columns.concat([lastColumn])}
        footer={CollectionFooter}
        totalRecordsCount={totalDocumentsCount}
        fetchData={handlePaginationRecords}
        handleRowClick={handleRowClick}
        disableNextAndPrev={!canGoToNextPage}
        handleLimits={handleLimits}
      />
    </Box>
  );
}

export default DynamoData;
