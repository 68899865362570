import { Flex, Button, Link, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { AppTabs as AppTabsDetails, ProtectedAppRoutes, RouteLabels } from 'routing-details';
import { Column } from 'react-table';
import { dataToItem, itemToData } from 'dynamo-converters';
import {
  commonDynamoAPI,
  getMetaData,
  getPermissions,
  updateCollectionProperties,
} from '../../../apis/collections';
import Plus from '../../../assets/icons/Plus';
import {
  DynamoAttributeTypes,
  DynamoFilterType,
  DynamoHeaders,
  DynamoScanItemKeys,
} from '../../../constants/collections';
import { AlertStatus } from '../../../constants/common';
import { NumberConstants, StringConstants } from '../../../constants/userMessages';
import AppBreadcrumb from '../../../elements/AppBreadcrumb';
import AppTabs from '../../../elements/AppTabs';
import DynamoCollectionHeader from '../../../elements/collection/DynamoCollectionHeader';
import PageContent from '../../../elements/page/PageContent';
import PageHeader from '../../../elements/page/PageHeader';
import DynamoItemModal from '../../../modals/collections/DynamoItemModal';
import { useToast } from '../../../providers/toastContext';
import {
  CollectionParams,
  CollectionProperties,
  DynamoFilterRequest,
  DynamoRecordsScan,
  DynamoTableRequest,
  KeySchema,
  PaginationParams,
  AttributeDefinitions,
  DynamoFilterAttributes,
  ItemKey,
  DynamoScanItem,
} from '../../../types/collections';
import {
  addEllipsis,
  getDisableStatusByPermission,
  getDynamoFilterQueryKeys,
  getImmediatePath,
  getModifiedExclusiveStartKey,
} from '../../../utils/helper';
import { useConfig } from '../../../providers/configContext';
import AppSpinner from '../../../elements/AppSpinner';

const { DynamoCollectionTabs } = AppTabsDetails;

function getHeader(value: string) {
  return (
    <Text color="neutral.400" fontWeight="hairline">
      {addEllipsis(value)}
    </Text>
  );
}

function DynamoView() {
  const { appToast, throwAppError } = useToast();

  const { pathname } = useLocation();
  const params = useParams<keyof CollectionParams>() as CollectionParams;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editorValue, setEditorValue] = useState({});
  const [isDocumentEditing, setIsDocumentEditing] = useState(false);
  const [showHeaderItem, setShowHeaderItem] = useState(true);
  const [streamStatus, setStreamStatus] = useState(false);
  const [primaryKeys, setPrimaryKeys] = useState<string[]>([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const newPageIndexRef = useRef<number>(0);
  const [hasCollectionPermission, setCollectionPermission] = useState(true);
  const { documentationURLs } = useConfig();
  const [canGoToNextPage, setCanGoToNextPage] = useState(false);
  const [limit, setLimit] = useState(NumberConstants.DYNAMO_PAGE_SIZE);
  const emptyAttribute: DynamoFilterAttributes = useMemo(
    () => ({
      comparator: '=',
      keyName: '',
      keyType: DynamoAttributeTypes.STRING,
      value: '',
    }),
    [],
  );
  const [filterAttributes, setFilterAttributes] = useState<DynamoFilterAttributes[]>([
    emptyAttribute,
  ]);
  const [filterType, setFilterType] = useState<DynamoFilterType>(DynamoFilterType.SCAN);
  const [indexName, setIndexName] = useState('');
  const [columns, setColumns] = useState<Column[]>([]);
  const [filteredData, setFilteredData] = useState<Column[]>([]);
  const [itemKeys, setItemKeys] = useState<ItemKey>({});
  const scannedItemsCountRef = useRef<number>(0);
  const [filteredItems, setFilteredItems] = useState<DynamoScanItem[]>([]);
  const filteredItemsRef = useRef<DynamoScanItem[]>([]);
  const lastEvaluatesKeysRef = useRef<Record<string, any>[]>([]);
  const [currentLastEvaluatedKey, setCurrentLastEvaluatedKey] = useState<Record<string, any>>();
  const bodyRef = useRef<DynamoFilterRequest>();
  const attributionDefinitionRef = useRef<AttributeDefinitions[]>();

  const { isLoading: isPermissionsLoading } = useQuery(
    'collectionPermissions',
    () => getPermissions(params.collection),
    {
      onSuccess: (res) => {
        setCollectionPermission(getDisableStatusByPermission(res.data.result));
      },
    },
  );

  const resetFilters = useCallback(() => {
    setFilterAttributes([]);
    setCurrentLastEvaluatedKey(undefined);
    setCurrentPageIndex(0);
    setFilterAttributes([emptyAttribute]);
  }, [emptyAttribute]);

  useEffect(() => {
    resetFilters();
  }, [pathname, resetFilters]);

  const toggleDynamoItemModal = () => {
    if (isModalVisible) {
      setIsDocumentEditing(false);
    }
    setIsModalVisible((value) => !value);
  };

  const verifyPaginationStatus = (scanResponse: DynamoRecordsScan) => {
    if (scanResponse.Items.length >= NumberConstants.DYNAMO_PAGE_SIZE) {
      const body = {
        TableName: params.collection,
        Limit: limit,
        ExclusiveStartKey: scanResponse.LastEvaluatedKey,
      };

      commonDynamoAPI(body, DynamoHeaders.DynamoScan).then((res) => {
        if (res.data.Items && res.data.Items.length > 0) {
          setCanGoToNextPage(true);
        }
      });
    }
  };

  const { mutate: dynamoRecordsScanMutation } = useMutation(
    ({ body, action }: { body: DynamoFilterRequest; action: string }) =>
      commonDynamoAPI(body, action),
    {
      onError: (err) => throwAppError(err),
      onSuccess: (res) => {
        const { Items } = res.data;
        setFilteredItems(Items);
        setCurrentPageIndex(newPageIndexRef.current);
        verifyPaginationStatus(res.data);
      },
    },
  );

  const { mutate: dynamoDescriptionMutation, data: descriptionData } = useMutation(
    () => commonDynamoAPI({ TableName: params.collection }, DynamoHeaders.DynamoDescribe),
    {
      onError: (err) => throwAppError(err),
      onSuccess: (res) => {
        bodyRef.current = {
          Limit: limit,
          TableName: params.collection,
        };
        setItemsCount(res.data.Table.ItemCount);
        attributionDefinitionRef.current = res.data.Table.AttributeDefinitions;

        if (bodyRef.current.ExclusiveStartKey && attributionDefinitionRef.current) {
          // remove unwanted keys in 'ExclusiveStartKey', the keys in 'KeySchema' should only exist
          bodyRef.current.ExclusiveStartKey = getModifiedExclusiveStartKey(
            bodyRef.current.ExclusiveStartKey,
            attributionDefinitionRef.current,
          );
        }

        dynamoRecordsScanMutation(
          {
            body: bodyRef.current,
            action: DynamoHeaders.DynamoScan,
          },
          {
            onSuccess: (newRes) => {
              setCurrentLastEvaluatedKey(newRes.data.LastEvaluatedKey);
            },
          },
        );
      },
    },
  );

  const handleScanKeys = () => {
    if (bodyRef.current) {
      if (currentPageIndex < newPageIndexRef.current) {
        // going to next page
        if (currentLastEvaluatedKey) {
          lastEvaluatesKeysRef.current.push(currentLastEvaluatedKey);
        }
        bodyRef.current.ExclusiveStartKey =
          lastEvaluatesKeysRef.current[lastEvaluatesKeysRef.current.length - 1];
      } else if (currentPageIndex > newPageIndexRef.current) {
        // going to prev page
        setCurrentLastEvaluatedKey(lastEvaluatesKeysRef.current.pop());
        bodyRef.current.ExclusiveStartKey =
          lastEvaluatesKeysRef.current[lastEvaluatesKeysRef.current.length - 1];
      } else {
        // existing page
        bodyRef.current.ExclusiveStartKey =
          lastEvaluatesKeysRef.current[lastEvaluatesKeysRef.current.length - 1];
      }

      const validAttributes = filterAttributes.filter((item) => item.keyName && item.value);

      if (validAttributes.length) {
        const { FilterExpression, ExpressionAttributeNames, ExpressionAttributeValues } =
          getDynamoFilterQueryKeys(filterAttributes);
        bodyRef.current.ExpressionAttributeNames = ExpressionAttributeNames;
        bodyRef.current.ExpressionAttributeValues = ExpressionAttributeValues;

        if (filterType === DynamoFilterType.SCAN) {
          bodyRef.current.FilterExpression = FilterExpression;
        } else {
          bodyRef.current.KeyConditionExpression = FilterExpression;
        }
        if (descriptionData && descriptionData.data.Table.GlobalSecondaryIndexes && indexName) {
          bodyRef.current.IndexName = indexName;
        }
      }
    }
  };

  useEffect(() => {
    if (pathname.endsWith(`${params.collection}`) || pathname.endsWith(`${params.collection}/`)) {
      dynamoDescriptionMutation();
    }
  }, [dynamoDescriptionMutation, params.collection, pathname, limit]);

  useEffect(() => {
    if (!isDocumentEditing) {
      const data: Record<string, any> = {};
      if (descriptionData && descriptionData.data.Table) {
        const { Table } = descriptionData.data;
        Table.KeySchema.forEach((item: KeySchema, index: number) => {
          if (
            Table.AttributeDefinitions[index] &&
            Table.AttributeDefinitions[index].AttributeType === DynamoAttributeTypes.STRING
          ) {
            data[item.AttributeName] = '';
          } else {
            data[item.AttributeName] = 0;
          }
        });
        setEditorValue(data);
      }
    }
  }, [descriptionData, isDocumentEditing]);

  useEffect(() => {
    let cols: Column[] = [];
    let data: Column[] = [];
    if (
      descriptionData &&
      descriptionData.data &&
      descriptionData.data.Table &&
      descriptionData.data.Table.KeySchema &&
      descriptionData.data.Table.KeySchema.length
    ) {
      let initialPrimaryKeys: string[] = [];
      // initialPrimaryKeys can be used as selectors in filtering
      descriptionData.data.Table.KeySchema.forEach((item: KeySchema) => {
        initialPrimaryKeys = initialPrimaryKeys.concat([item.AttributeName]);
        cols = cols.concat([
          {
            Header: <Text textTransform="capitalize">{item.AttributeName}</Text>,
            accessor: item.AttributeName,
            Cell: ({ row: { original } }: Record<string, any>) =>
              getHeader(original[item.AttributeName]),
          },
        ]);
      });
      setPrimaryKeys(initialPrimaryKeys);
    }

    let keys: string[] = [];
    filteredItems.forEach((item: DynamoScanItem) => {
      keys = keys.concat(Object.keys(item));
    });
    // removing duplicates
    const uniqueKeys: string[] = keys.filter((key, index) => keys.indexOf(key) === index);

    // adding extra columns if any
    uniqueKeys.forEach((uniqueKey) => {
      if (!cols.find((colItem) => colItem.accessor === uniqueKey))
        cols = cols.concat({
          accessor: uniqueKey,
          Header: (
            <Text textTransform="capitalize" color="documents_table_header_color">
              {uniqueKey}
            </Text>
          ),
          Cell: ({ row: { original } }: Record<string, any>) => getHeader(original[uniqueKey]),
        });
    });

    // creating table rows
    let itemKeysData: ItemKey = {};
    filteredItems.forEach((item: DynamoScanItem) => {
      let rowObj = {} as Column;
      uniqueKeys.forEach((record) => {
        if (item[record]) {
          let existingKey;
          if (item[record].S || item[record].S === '') {
            existingKey = DynamoScanItemKeys.STRING;
          } else if (item[record].N) {
            existingKey = DynamoScanItemKeys.NUMBER;
          } else if (item[record].BOOL) {
            existingKey = DynamoScanItemKeys.BOOLEAN;
          } else if (item[record].B) {
            existingKey = DynamoScanItemKeys.BLOB;
          }

          // 'itemKeysData'is populating with key & its type. It is used to parse values to its original types when editing
          if (
            Object.keys(itemKeysData).filter((itemKey) => itemKeysData[itemKey] === record)
              .length === 0
          ) {
            itemKeysData = { ...itemKeysData, [record]: existingKey };
          }

          if (existingKey) {
            rowObj = {
              ...rowObj,
              [record]: String(item[record][existingKey]),
            };
          } else {
            const itemToDataValue = itemToData({ data: item[record] });
            rowObj = {
              ...rowObj,
              [record]: JSON.stringify(itemToDataValue.data),
            };
          }
        } else {
          rowObj = { ...rowObj, [record]: 'undefined' };
        }
      });

      data = data.concat([rowObj]);
    });
    setItemKeys(itemKeysData);
    setFilteredData(data);
    setColumns(cols);
  }, [descriptionData, filteredItems, setPrimaryKeys]);

  const { mutate: dynamoMutation } = useMutation(
    ({ data, action }: { data: DynamoTableRequest; action: string }) =>
      commonDynamoAPI(data, action),
    {
      onError: (err) => throwAppError(err),
    },
  );

  const handleDynamoItem = (code: string, isCreating: boolean) => {
    const value = JSON.parse(code);

    let item = {};
    Object.keys(value).forEach((key) => {
      if (typeof value[key] === 'string') {
        item = { ...item, [key]: { [DynamoAttributeTypes.STRING]: value[key] } };
      } else if (typeof value[key] === 'number' && descriptionData) {
        const currentAttributeType: AttributeDefinitions =
          descriptionData.data.Table.AttributeDefinitions.find(
            (ad: AttributeDefinitions) => ad.AttributeName === key,
          );
        if (
          currentAttributeType &&
          currentAttributeType.AttributeType === DynamoAttributeTypes.BINARY
        ) {
          item = { ...item, [key]: { [DynamoAttributeTypes.BINARY]: String(value[key]) } };
        } else {
          item = { ...item, [key]: { [DynamoAttributeTypes.NUMBER]: String(value[key]) } };
        }
      } else {
        const dataToItemValue = dataToItem({ data: value[key] });
        item = { ...item, [key]: dataToItemValue.data };
      }
    });

    const obj = {
      data: { [isCreating ? 'Item' : 'Key']: item, TableName: params.collection },
      action: isCreating ? DynamoHeaders.PutItem : DynamoHeaders.DeleteItem,
    };

    dynamoMutation(obj, {
      onSuccess: () => {
        toggleDynamoItemModal();
        dynamoDescriptionMutation();
      },
    });
  };

  const handleEdit = (code: Record<any, any>) => {
    setEditorValue(code);
    setIsDocumentEditing(true);
    toggleDynamoItemModal();
  };

  const collectionPropertiesMutation = useMutation(
    (body: CollectionProperties) => updateCollectionProperties(params.collection, body),
    {
      onError: () => {
        appToast({
          alertDescription: StringConstants.STREAM_CANNOT_ENABLE,
          alertStatus: AlertStatus.ERROR,
        });
      },
      onSuccess: (res) => {
        appToast({
          alertDescription: `${params.collection} ${StringConstants.COLLECTION_PROPERTIES_UPDATED}`,
          alertStatus: AlertStatus.SUCCESS,
        });
        setStreamStatus(res.data.hasStream);
      },
    },
  );

  useQuery('metadata', () => getMetaData(params.collection), {
    onError: (err) => throwAppError(err),
    onSuccess: (res) => {
      setStreamStatus(res.data.hasStream);
    },
    refetchOnMount: true,
  });

  const handleCollectionProperties = () => {
    const body = {
      hasStream: !streamStatus,
    };
    collectionPropertiesMutation.mutate(body);
  };

  const handleScan = (
    body: DynamoFilterRequest,
    newFilterType: DynamoFilterType,
    callback?: () => void,
  ) => {
    dynamoRecordsScanMutation(
      {
        body,
        action:
          newFilterType === DynamoFilterType.SCAN ? DynamoHeaders.DynamoScan : DynamoHeaders.Query,
      },
      {
        onSuccess: (res) => {
          if (callback) callback();
          const { ScannedCount, Items, Count, LastEvaluatedKey } = res.data;
          setCurrentLastEvaluatedKey(LastEvaluatedKey);

          if (ScannedCount !== 0 && Count !== 0 && filteredItemsRef.current.length < 10) {
            // if there filters exists
            filteredItemsRef.current = filteredItemsRef.current.concat(Items).slice(0, limit);
            const newBody = {
              ...body,
              ExclusiveStartKey: filteredItemsRef.current[filteredItemsRef.current.length - 1],
            };
            handleScan(newBody, newFilterType);
          } else {
            setFilteredItems(filteredItemsRef.current);
            setCurrentLastEvaluatedKey(
              filteredItemsRef.current[filteredItemsRef.current.length - 1],
            );
            scannedItemsCountRef.current = 0;
            filteredItemsRef.current = [];
          }
        },
      },
    );
  };

  const handlePagination = ({ pageIndex }: PaginationParams) => {
    if (newPageIndexRef.current !== pageIndex) {
      newPageIndexRef.current = pageIndex;
      handleScanKeys();
      if (bodyRef.current) {
        handleScan(bodyRef.current, filterType, undefined);
      }
    }
  };

  const handleLimits = (newLimit: string) => {
    setLimit(Number(newLimit));
  };

  const appTabsView = useMemo(
    () => (
      <AppTabs
        tabDetails={DynamoCollectionTabs}
        basePath={`${ProtectedAppRoutes.Collections}/dynamo/${getImmediatePath(
          pathname,
          ProtectedAppRoutes.Collections,
          2,
        )}`}
        mb="1.5"
        tabListProps={{ pl: '4' }}
      />
    ),
    [pathname],
  );

  if (isPermissionsLoading) {
    return <AppSpinner />;
  }

  return (
    <>
      <PageHeader p="0">
        <Flex justify="space-between" align="center" px="4" py="2">
          <Flex align="center">
            <AppBreadcrumb
              data={[
                {
                  name: RouteLabels.Collections,
                  path: `/${ProtectedAppRoutes.Collections}`,
                },
                {
                  name: params.collection,
                },
              ]}
            />
            {showHeaderItem && (
              <Button
                onClick={toggleDynamoItemModal}
                leftIcon={<Plus fill="plus_circle_icon_color" />}
                variant="rounded_solid"
                mx="4"
                disabled={!hasCollectionPermission}
                size="sm"
                data-cy="newItem"
              >
                New Item
              </Button>
            )}
          </Flex>
          <Link href={documentationURLs.dynamo} isExternal variant="rounded_solid">
            Documentation
          </Link>
        </Flex>
        {appTabsView}
        {showHeaderItem && (
          <DynamoCollectionHeader
            streamStatus={streamStatus}
            handleStreamStatus={handleCollectionProperties}
            primaryKeys={primaryKeys}
            handleScan={handleScan}
            descriptionData={descriptionData?.data}
            hasCollectionPermission={hasCollectionPermission}
            filterAttributes={filterAttributes}
            handleAttributes={(data) => setFilterAttributes(data)}
            filterType={filterType}
            handleFilterType={(value) => setFilterType(value)}
            indexName={indexName}
            handleIndex={(value) => setIndexName(value)}
            limit={limit}
          />
        )}
      </PageHeader>
      <PageContent>
        <Outlet
          context={{
            toggleDynamoItemModal,
            handleEdit,
            setShowHeaderItem,
            totalDocumentsCount: itemsCount,
            handlePaginationRecords: handlePagination,
            hasCollectionPermission,
            canGoToNextPage,
            handleLimits,
            columns,
            itemKeys,
            filteredData,
          }}
        />
        {isModalVisible && (
          <DynamoItemModal
            isOpen={isModalVisible}
            isEdit={isDocumentEditing}
            data={editorValue}
            toggleModal={toggleDynamoItemModal}
            handleSubmit={handleDynamoItem}
            readOnly={!hasCollectionPermission}
          />
        )}
      </PageContent>
    </>
  );
}

export default DynamoView;
