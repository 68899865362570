import { createIcon } from '@chakra-ui/react';

const DoubleLeft = createIcon({
  displayName: 'DoubleLeft',
  viewBox: '0 0 24 24',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.107 4.215a1.313 1.313 0 0 1 0 1.856L14.177 12l5.93 5.93a1.313 1.313 0 0 1-1.857 1.855l-6.857-6.857a1.312 1.312 0 0 1 0-1.856l6.857-6.857a1.313 1.313 0 0 1 1.857 0Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.535 4.215a1.312 1.312 0 0 1 0 1.856L5.606 12l5.93 5.93a1.312 1.312 0 1 1-1.857 1.855l-6.857-6.857a1.313 1.313 0 0 1 0-1.856l6.857-6.857a1.312 1.312 0 0 1 1.856 0Z"
    />,
  ],
});

export default DoubleLeft;
