import { Box, PositionProps } from '@chakra-ui/react';

export default function PageContent({
  children,
  ...styles
}: {
  children?: React.ReactNode;
  h?: string & {};
  p?: string & {};
  position?: PositionProps['position'];
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box bg="white" m={4} {...styles} mb={16}>
      {children}
    </Box>
  );
}

PageContent.defaultProps = {
  children: undefined,
  h: undefined,
  p: undefined,
  position: undefined,
};
