import { lazy } from 'react';
import { ElementIdentifiers, VendorUserConfig } from 'routing-details';
import { RouteObject } from 'react-router-dom';
import { OpenAppRoutes, RouteTypes } from '../constants/route';
import ApiReferenceIcon from '../assets/icons/ApiReference';
import CollectionIcon from '../assets/icons/Collection';
import DashboardIcon from '../assets/icons/Dashboard';
import DocumentationIcon from '../assets/icons/Documentation';
import GraphIcon from '../assets/icons/Graph';
import QueryWorker from '../assets/icons/QueryWorker';
import SearchIcon from '../assets/icons/Search';
import FunctionWorkerIcon from '../assets/icons/FunctionWorker';
import StreamIcon from '../assets/icons/Stream';
import StreamWorkerIcon from '../assets/icons/StreamWorker';
import SupportIcon from '../assets/icons/Support';
import LocationIcon from '../assets/icons/Location';
import GeoFabricIcon from '../assets/icons/GeoFabric';
import TenantIcon from '../assets/icons/Tenant';
import AccountIcon from '../assets/icons/Account';
import UsersIcon from '../assets/icons/Users';
import APIKeysIcon from '../assets/icons/APIKeys';

import { IElementMappings, ProtectedRouteDetails, RouteComponentMapType } from '../types/routes';
import DynamoView from './collections/collection/DynamoView';
import DynamoData from './collections/collection/DynamoData';
import DynamoIndexes from './collections/collection/DynamoIndexes';
import DynamoSettings from './collections/collection/DynamoSettings';
import Studio from '../assets/icons/Studio';

const Login = lazy(() => import('./Login'));
const Maintenance = lazy(() => import('./Maintenance'));

const Dashboard = lazy(() => import('./Dashboard'));

const Fabrics = lazy(() => import('./fabrics/Fabrics'));
const FabricDetails = lazy(() => import('./fabrics/FabricDetails'));

const Graphs = lazy(() => import('./graphs/Graphs'));
const GraphView = lazy(() => import('./graphs/GraphView'));

const Account = lazy(() => import('./account/Account'));
const Users = lazy(() => import('./users/Users'));
const APIKeys = lazy(() => import('./apiKeys/ApiKeys'));
const Plans = lazy(() => import('./account/Plans'));
const ApikeyDetails = lazy(() => import('./apiKeys/ApikeyDetails'));
const Attributes = lazy(() => import('./apiKeys/Attributes'));
const UserApikeyPermission = lazy(() => import('./apiKeys/UserApikeyPermission'));
const Overview = lazy(() => import('./account/Overview'));
const AccountSettings = lazy(() => import('./account/Settings'));

const Collections = lazy(() => import('./collections/Collections'));
const DocumentEdgeCollectionView = lazy(
  () => import('./collections/collection/DocumentEdgeCollectionView'),
);
const DocumentEdgeCollectionData = lazy(
  () => import('./collections/collection/DocumentEdgeCollectionData'),
);
const CollectionIndexes = lazy(() => import('./collections/collection/Indexes'));
const CollectionStream = lazy(() => import('./collections/collection/Stream'));
const CollectionSettings = lazy(() => import('./collections/collection/Settings'));
const DocumentView = lazy(() => import('./collections/DocumentView'));
const KVCollectionView = lazy(() => import('./collections/collection/KVCollectionView'));
const KVCollectionData = lazy(() => import('./collections/collection/KVCollectionData'));

const Queries = lazy(() => import('./queries/Queries'));
const QueryEditor = lazy(() => import('./queries/QueryEditor'));
const QueryWorkers = lazy(() => import('./queries/QueryWorkers'));
const RunningQueries = lazy(() => import('./queries/RunningQueries'));
const SlowQueriesHistory = lazy(() => import('./queries/SlowQueriesHistory'));

const ApiReference = lazy(() => import('./apiReference/ApiReference'));
const AdminReference = lazy(() => import('./apiReference/Internal'));
const ExternalReference = lazy(() => import('./apiReference/External'));

const Search = lazy(() => import('./Search'));
const Streams = lazy(() => import('./streams/Streams'));
const StreamDetails = lazy(() => import('./streams/StreamDetails'));

const StreamWorkers = lazy(() => import('./streamWorkers/StreamWorkers'));
const StreamWorkerEditor = lazy(() => import('./streamWorkers/StreamWorkerEditor'));
const StreamWorkerSamples = lazy(() => import('./streamWorkers/StreamWorkerSamples'));
const StreamWorkerLogs = lazy(() => import('./streamWorkers/StreamLogs'));
const SavedStreamWorkers = lazy(() => import('./streamWorkers/SavedStreamWorkers'));

const Locations = lazy(() => import('./Locations'));

const Tenants = lazy(() => import('./tenants/Tenants'));
const TenantTable = lazy(() => import('./tenants/TenantsTable'));
const PlatformLimits = lazy(() => import('./tenants/PlatformLimits'));
const TenantDetails = lazy(() => import('./tenants/TenantDetails'));

const FunctionWorker = lazy(() => import('./functionWorkers/FunctionWorkers'));

export const ElementMappings: IElementMappings = {
  [ElementIdentifiers.Dashboard]: {
    element: <Dashboard />,
    navIcon: DashboardIcon,
  },
  [ElementIdentifiers.Collections]: {
    element: <Collections />,
    navIcon: CollectionIcon,
  },
  [ElementIdentifiers.CollectionDocument]: {
    element: <DocumentEdgeCollectionView />,
  },
  [ElementIdentifiers.CollectionDocumentData]: {
    element: <DocumentEdgeCollectionData />,
  },
  [ElementIdentifiers.CollectionIndexes]: {
    element: <CollectionIndexes />,
  },
  [ElementIdentifiers.CollectionStreams]: {
    element: <CollectionStream />,
  },
  [ElementIdentifiers.CollectionSettings]: {
    element: <CollectionSettings />,
  },
  [ElementIdentifiers.DocumentView]: {
    element: <DocumentView />,
  },
  [ElementIdentifiers.KVCollectionView]: {
    element: <KVCollectionView />,
  },
  [ElementIdentifiers.KVCollectionData]: {
    element: <KVCollectionData />,
  },
  [ElementIdentifiers.DynamoView]: {
    element: <DynamoView />,
  },
  [ElementIdentifiers.DynamoData]: {
    element: <DynamoData />,
  },
  [ElementIdentifiers.DynamoIndexes]: {
    element: <DynamoIndexes />,
  },
  [ElementIdentifiers.DynamoSettings]: {
    element: <DynamoSettings />,
  },
  [ElementIdentifiers.Graphs]: {
    element: <Graphs />,
    navIcon: GraphIcon,
  },
  [ElementIdentifiers.GraphView]: {
    element: <GraphView />,
  },
  [ElementIdentifiers.Search]: {
    navIcon: SearchIcon,
    element: <Search />,
  },
  [ElementIdentifiers.Functions]: {
    navIcon: FunctionWorkerIcon,
    element: <FunctionWorker />,
  },
  [ElementIdentifiers.QueryWorkers]: {
    navIcon: QueryWorker,
    element: <Queries />,
  },
  [ElementIdentifiers.QueryEditor]: {
    element: <QueryEditor />,
  },
  [ElementIdentifiers.SavedQueryWorkers]: {
    element: <QueryWorkers />,
  },
  [ElementIdentifiers.RunningQueries]: {
    element: <RunningQueries />,
  },
  [ElementIdentifiers.SlowQueriesHistory]: {
    element: <SlowQueriesHistory />,
  },
  [ElementIdentifiers.Streams]: {
    navIcon: StreamIcon,
    element: <Streams />,
  },
  [ElementIdentifiers.StreamDetails]: {
    element: <StreamDetails />,
  },
  [ElementIdentifiers.StreamWorkers]: {
    navIcon: StreamWorkerIcon,
    element: <StreamWorkers />,
  },
  [ElementIdentifiers.StreamWorkerEditor]: {
    element: <StreamWorkerEditor />,
  },
  [ElementIdentifiers.StreamWorkerSamples]: {
    element: <StreamWorkerSamples />,
  },
  [ElementIdentifiers.StreamWorkerLogs]: {
    element: <StreamWorkerLogs />,
  },
  [ElementIdentifiers.SavedStreamWorkers]: {
    element: <SavedStreamWorkers />,
  },
  [ElementIdentifiers.GeoFabrics]: {
    navIcon: GeoFabricIcon,
    element: <Fabrics />,
  },
  [ElementIdentifiers.FabricDetails]: {
    element: <FabricDetails />,
  },
  [ElementIdentifiers.Locations]: {
    element: <Locations />,
    navIcon: LocationIcon,
  },
  [ElementIdentifiers.Tenants]: {
    navIcon: TenantIcon,
    element: <Tenants />,
  },
  [ElementIdentifiers.TenantTable]: {
    element: <TenantTable />,
  },
  [ElementIdentifiers.PlatformLimits]: {
    element: <PlatformLimits />,
  },
  [ElementIdentifiers.TenantDetails]: {
    element: <TenantDetails />,
  },
  [ElementIdentifiers.Account]: {
    navIcon: AccountIcon,
    element: <Account />,
  },
  [ElementIdentifiers.AccountOverView]: {
    element: <Overview />,
  },
  [ElementIdentifiers.AccountSettings]: {
    element: <AccountSettings />,
  },
  [ElementIdentifiers.Users]: {
    navIcon: UsersIcon,
    element: <Users />,
  },
  [ElementIdentifiers.APIKeys]: {
    navIcon: APIKeysIcon,
    element: <APIKeys />,
  },
  [ElementIdentifiers.Plans]: {
    element: <Plans />,
  },
  [ElementIdentifiers.Attributes]: {
    element: <Attributes />,
  },
  [ElementIdentifiers.UserAPIkeyPermission]: {
    element: <UserApikeyPermission />,
  },
  [ElementIdentifiers.APIkeyDetails]: {
    element: <ApikeyDetails />,
  },
  [ElementIdentifiers.Studio]: {
    navIcon: Studio,
  },
  [ElementIdentifiers.ApiReference]: {
    navIcon: ApiReferenceIcon,
    element: <ApiReference />,
  },
  [ElementIdentifiers.ExternalReference]: {
    element: <ExternalReference />,
  },
  [ElementIdentifiers.AdminReference]: {
    element: <AdminReference />,
  },
  [ElementIdentifiers.Documentation]: {
    navIcon: DocumentationIcon,
  },
  [ElementIdentifiers.Support]: {
    navIcon: SupportIcon,
  },
};

const formatRoutes = (routeDetails: RouteObject) => {
  if (routeDetails.children) {
    return routeDetails.children.map((childrenDetails) => {
      const { element: nestedElement } =
        ElementMappings[childrenDetails.element as ElementIdentifiers];
      const childrenRouteObject: RouteObject = {
        ...childrenDetails,
        element: nestedElement,
      };
      const hasMoreChildren = formatRoutes(childrenDetails);
      if (hasMoreChildren) {
        childrenRouteObject.children = hasMoreChildren;
      }
      return childrenRouteObject;
    });
  }
  return undefined;
};

export const getProtectedRoutes = (routeDetails: VendorUserConfig['routeDetails'] = []) => {
  const navDetails = routeDetails.map((detail) => {
    const { element } = ElementMappings[detail.route.element];
    const modifiedDetail = JSON.parse(JSON.stringify(detail)) as ProtectedRouteDetails;
    if (element) {
      modifiedDetail.route.element = element;
      if (detail.route.deleteElement) {
        delete modifiedDetail.route.element;
      }
    }

    const childrenRoutes = formatRoutes(modifiedDetail.route);
    if (childrenRoutes) {
      modifiedDetail.route.children = childrenRoutes;
    }

    return modifiedDetail;
  });
  return navDetails;
};

const RouteComponentMappings: RouteComponentMapType = {
  [RouteTypes.Open]: {
    [OpenAppRoutes.Root]: {
      route: {
        path: OpenAppRoutes.Root,
        element: <Login />,
      },
    },
    [OpenAppRoutes.Login]: {
      route: {
        path: OpenAppRoutes.Login,
        element: <Login />,
      },
    },
    [OpenAppRoutes.Maintenance]: {
      route: {
        path: OpenAppRoutes.Maintenance,
        element: <Maintenance />,
      },
    },
  },
};

export default RouteComponentMappings;
