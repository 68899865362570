import { useLocation } from 'react-router-dom';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { NavItemProps } from '../../types/sidebar';

function NavItem({ route, navIcon, label, isInnerItem, isOutlinedItem }: NavItemProps) {
  const location = useLocation();
  const { path = '' } = route;
  const shouldHighlight = () => location.pathname.split('/')[1] === path;

  const getBorderColor = () => {
    if (shouldHighlight()) {
      return 'nav_item_active_bg_color';
    }
    return isOutlinedItem ? 'nav_item_border_color' : 'transparent';
  };

  const getColor = () => {
    if (shouldHighlight()) {
      return 'nav_item_active_color';
    }
    return isInnerItem ? 'positive' : 'nav_item_color';
  };

  return (
    <Flex
      align="center"
      borderColor={getBorderColor()}
      borderRadius="base"
      px="3"
      py="2.5"
      role="group"
      cursor="pointer"
      bg={shouldHighlight() ? 'nav_item_active_bg_color' : 'none'}
      h="10"
      _hover={{
        bg: shouldHighlight() ? 'nav_item_active_bg_color' : 'nav_item_hover_bg_color',
      }}
    >
      <Flex>
        <Icon
          as={navIcon}
          fill={shouldHighlight() ? 'nav_icon_active_color' : 'nav_icon_color'}
          opacity="nav_icon_opacity"
          fontSize="md"
          mr="3.5"
        />
      </Flex>
      <Text fontSize="md" lineHeight="10" color={getColor()} noOfLines={1}>
        {label}
      </Text>
    </Flex>
  );
}

export default NavItem;

NavItem.defaultProps = {
  isInnerItem: false,
};
