import { createIcon } from '@chakra-ui/react';

const Studio = createIcon({
  displayName: 'StudioIcon',
  viewBox: '0 0 24 22',
  path: [
    <path
      fillRule="evenodd"
      d="M4.23 6.346a1 1 0 0 1 1-1h3.385a1 1 0 1 1 0 2H5.231a1 1 0 0 1-1-1ZM4.23 11.423a1 1 0 0 1 1-1h1.693a1 1 0 1 1 0 2H5.231a1 1 0 0 1-1-1Z"
      clipRule="evenodd"
    />,
    <path
      fillRule="evenodd"
      d="M22.154.27H1.846C.826.27 0 1.095 0 2.114v13.539c0 1.02.827 1.846 1.846 1.846h6.985l-.893 2.23H6.5a1 1 0 1 0 0 2h8.861l.024.001H17.5a1 1 0 0 0 0-2h-1.438l-.893-2.231h6.985c1.02 0 1.846-.827 1.846-1.846V2.115C24 1.095 23.173.27 22.154.27ZM2 15.5V2.27h9.846V15.5H2Zm11.846-5.298V15.5H22v-3.63l-3.442-3.072a2.385 2.385 0 0 0-3.027.01l-1.685 1.394ZM22 9.19V2.27h-8.154v5.337l.41-.339a4.386 4.386 0 0 1 5.62.025L22 9.19ZM10.985 17.5h2.03l.893 2.23h-3.816l.893-2.23Z"
      clipRule="evenodd"
    />,
  ],
});

export default Studio;
