const baseStyle = {
  color: 'neutral.700',
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: 5,
};

export default {
  baseStyle,
};
