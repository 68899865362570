import { createIcon } from '@chakra-ui/react';

const DoubleRight = createIcon({
  displayName: 'DoubleRight',
  viewBox: '0 0 24 24',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.89 4.215a1.312 1.312 0 0 0 0 1.856L9.82 12l-5.93 5.93a1.312 1.312 0 1 0 1.857 1.855l6.857-6.857a1.312 1.312 0 0 0 0-1.856L5.747 4.215a1.312 1.312 0 0 0-1.856 0Z"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.462 4.215a1.312 1.312 0 0 0 0 1.856L18.392 12l-5.93 5.93a1.312 1.312 0 1 0 1.856 1.855l6.858-6.857a1.313 1.313 0 0 0 0-1.856l-6.858-6.857a1.312 1.312 0 0 0-1.856 0Z"
    />,
  ],
});

export default DoubleRight;
